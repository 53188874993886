<template>
    <KCourse loader-class="MBcont" course-id="1" title="Анатомия головы" :items="items">
        
            <div class="MBtextcont">
                <p>
                    Сегодня мы лепим глаза, нос, рот и ухо. Вас опять ждет очень много
                    материала! По итогу этого занятия у вас в руках будет готовая
                    пластилиновая головка )
                </p>
                <p>
                    Пластилин материал не очень благодатный для лепки кукольных головок… Но
                    для экспериментов и набросков вполне подойдет.
                </p>
            </div>

            <VideoView video-id="dd6fb5a583d64a26bfe9890f05bf64d9"/>
    
        
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Лепим череп", url:"/mycourses/head/1"},
                { title: "Делаем обрубовку", url:"/mycourses/head/2"},
                { title: "Лепим лицо", url:"/mycourses/head/3"},
                { title: "Основа из La Doll", url:"/mycourses/head/4"},
                { title: "Создаем объемы", url:"/mycourses/head/5"},
                { title: "Доработка деталей", url:"/mycourses/head/6"},
            ],
        }
        }
    }
</script>